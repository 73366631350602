import { useEffect, useState } from "react"
import { Button, Form, Input, TextArea,Image } from "../../../styled/form"
import Spin from "../../Spin/Spin";
import axios from "axios";

interface EditProdutosProps{
    produto:any
    update:any
}
interface ProdutoProps{
    name:string;
    categoria:number;
    descricao:string;
    image:any
    id:string
  }
  const url = process.env.REACT_APP_URL_API

const EditProdutos = ({produto, update}:EditProdutosProps) =>{

    useEffect(()=>{
        setValues({ 
        id:produto.id,
        name:produto.name,
        categoria:1,
        descricao:produto.description,
        image:produto.image
        })
      },[produto])


    const [preview, setPreview] = useState<string>(produto.image)
    const [spinOpen, setSpinOpen] = useState<boolean>(false)
    const [values, setValues] = useState<ProdutoProps>({
        id:produto.id,
        name:produto.name,
        categoria:1,
        descricao:produto.description,
        image:produto.image
      })



    function changeImage(el:any){
        setValues({...values, image:el})
        var imagePreview = URL.createObjectURL(el)
        console.log(imagePreview)
        setPreview(imagePreview)
    }
    
    async function handleSubmit(e:any)
    {
      e.preventDefault();
      setSpinOpen(true)
      console.log(values)
      const options ={
        headers:{
          token: localStorage.getItem('token')
        }
      }
      await axios.put(`${url}/produto`,{name:values.name, id:values.id, descricao:values.descricao,} ,options )
      .then((res)=>{
        setSpinOpen(false)
         
        update()
      })
      .catch((err)=>{
        console.log(err)
        setSpinOpen(false)
      })
    }

    return(
        <>
        <Spin open={spinOpen}></Spin>
             <Form onSubmit={handleSubmit}>
          <Image src={`${url}/files/produtos/${preview}`} className="mb-5"></Image>
          {/* <label htmlFor="name">Nome:</label> */}
          <Input id="name" type="text" value={values.name} onChange={(e)=> setValues({...values, name:e.target.value})} placeholder="Nome"></Input>
          {/* <Input type="text" value={values.categoria} onChange={(e)=> setValues({...values, categoria:e.target.value})} placeholder="Categoria"></Input> */}
          <TextArea placeholder="Descrição" value={values.descricao} onChange={(e)=> setValues({...values, descricao:e.target.value})}></TextArea>
          <Button type="submit">Editar</Button>
        </Form>
        </>
    )
}

export default EditProdutos