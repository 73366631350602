import styled from "styled-components";


export const Content = styled.div`
overflow-x: hidden;
iframe{
    width: 100%;
    height: 300px;
}

.infos-footer{
    background-color: #e3e3e3;
    height: 100%;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    ul{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        li{
            list-style: none;
            color: #3e3e3e;
            svg{
                margin-right: .5rem;
            }
        }
    }
}

`