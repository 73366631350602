import { NavLink } from "react-router-dom";
import { Content } from "./styled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CiMenuBurger } from "react-icons/ci";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FiSearch } from "react-icons/fi";
import ModalProdutos from "../ModalProdutos/ModalProdutos";

const NavBar = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] = useState<any>("")
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const { produtos } = useSelector((root: any) => root.produtosReducer);
  var produtosFiltrados: any = [];

  const lista = (
    <ul>
      <li>
        {" "}
        <NavLink to={"/"}>Início</NavLink>
      </li>
      <li>
        <NavLink to={"/catalogo"}>Catálogo</NavLink>
      </li>
      <li>
        {" "}
        <NavLink to={"/produtos"}>Produtos</NavLink>
      </li>
      <li>
        {" "}
        <a target="blank" href={"https://wa.me/5511948604493"}>Contato</a>
      </li>
    </ul>
  );

  function changeModal(item:any){
    setSelectedProduct(item)
    setModalOpen(true)
}
function closeModal(){
    setModalOpen(false)
}
const handleClick = (option:any) => {
  // Esta função será chamada quando uma opção for clicada.
  const produtoFiltrado = produtos.filter((x:any) => x.name == option)
  changeModal(produtoFiltrado[0])
};

  return (
    <>
    <ModalProdutos
        fecha={closeModal}
        open={modalOpen}
        produto={selectedProduct}
      ></ModalProdutos>
      <Content >
        <Container >
          <Row  className=" justify-content-md-center justify-content-start align-items-center pt-5">
            <Col  className="d-none d-md-block" md={8}>
              {lista}
            </Col>

            <Col xs={3} className="d-md-none d-flex align-item-center ">
              <div onClick={() => setSideBarOpen(true)} className="burger">
                <CiMenuBurger />
              </div>

              <div className="off-canvas">
                <Offcanvas
                  show={sideBarOpen}
                  onHide={() => setSideBarOpen(false)}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      <i>
                        <strong>Rocket</strong> Tail
                      </i>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>{lista}</Offcanvas.Body>
                </Offcanvas>
              </div>
            </Col>
            <Col md={4} lg={3} xs={9}>
              <div className="search">
                <Stack
                  spacing={2}
                  sx={{ width: "100%" }}
                  className="position-relative"
                >
                  <Autocomplete
                    id="Produtos"
                    freeSolo
                    options={produtos.map((option: any) => option.name)}
                    renderInput={(params) => (
                      <TextField
                      
                        {...params}
                      ></TextField>
                    )}
                    renderOption={(props:any, option:any) => (
                      <li className="p-3 li-nav" {...props.name} onClick={() => handleClick(option)}>
                        {option}
                      </li>
                    )}
                  />
                </Stack>
              </div>
            </Col>
          </Row>
        </Container>
      </Content>
    </>
  );
};
export default NavBar;
