import styled from "styled-components";
import plus from '../images/plus.svg';

export const Form = styled.form`
display: flex;
flex-direction: column;
align-items: center;
padding: 1rem 0;
`

export const Input = styled.input`
border: none;
border-bottom: 2px solid black;
width: 350px;
background-color: transparent;
margin-bottom: 2rem;
padding:.3rem .5rem;
outline: none;
`

export const TextArea = styled.textarea`
width: 350px;
height: 350px;
border-color: 2px solid black;
padding: .3rem .5rem;
resize: none;
outline: none;
background-color: transparent;
`

export const Button = styled.button`
    background-color: #011094;
    border-radius: 10px 0px 10px 0px;
    border: none;
    margin-top: 1rem;
    padding: .5rem 1rem;
    color: #fff;
    font-weight: bold;

`

export const Image = styled.img`
border-radius: 50%;
height: auto;
max-width: 100%;
height: 200px;
width: 200px;
`

export const ButtonCadastrar = styled.button`
    color: #fff;
    background-color: #011094;
    border: none;
    padding: 0.5rem 1rem;
    position: relative;
    display: flex;
    align-items: center;
    transition: .3s;
    &:hover{
        background-color: #011094;
    }
    &::before{
        content: "";
        position: relative;
        background-image: url(${plus});
        width: 20px;
        height: 20px;
        background-size: contain;
        background-position: center;
        display: inline-block;
        margin-right: .5rem;
    }

`