import { useState } from "react"
import { ConteudoEditor, Editor } from "../../../styled/Edit"
import { Col, Container, Row } from "react-bootstrap"
import {GrClose} from 'react-icons/gr'
interface EditorProps{
    open:boolean;
    fecha:any;
    children:any
}
const Edit = ({open, fecha, children}:EditorProps) =>{
    
    return(
        <>
            <Editor open={open}>
                <Row className="pt-3">
                        <Col className="text-end" xs={11}>
                            <GrClose onClick={()=> fecha()}/>
                        </Col>
                </Row>

                <Row className=" pt-5">
                    {children}
                </Row>

                
            </Editor>
        </>
    )
}
export default Edit