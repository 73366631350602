import NavBar from "../Navbar/NavBar"
import { Content } from "./styled"

const ImageProdutos = () =>{


    return (
        <>
            <Content>
            <NavBar></NavBar>
            </Content>
        </>
    )
}

export default ImageProdutos