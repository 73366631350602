import styled from "styled-components";
import {darken} from 'polished'

export const Content = styled.div`

form{
  input{
    border-bottom:1px solid #ccc;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: .5rem;
    outline: none;
    margin-bottom: 1rem;
    width: 100%;
  }

  button{
    width: 50%;
    border: none;
    padding: .5rem 1rem;
    border-radius: 0px 10px 0px 10px;
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #011094;
    transition: .3s;
    &:hover{
      background-color: #000b6f;
    }
  }
}


`
