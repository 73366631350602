import {styled} from 'styled-components'

export const Content = styled.div`
position: relative;
left: 0;
top: 0;
z-index: 1;
width: 100vw;
/* overflow-x: hidden; */

ul{
  display: flex;
  gap: 3rem;
  justify-content: center;
  @media screen and (max-width: 992px){
    gap: 2rem;
  }
  li{
    list-style: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.2em;
    text-decoration: none;
    a{
      color: #fff;
      text-decoration: none;
      transition: .5s;
      font-size: 1.2em;
      &:hover{
        font-size: 1.3em;
      }
      &.active{
        text-decoration: underline;
      }
    }
  }
}
.css-10ceblr-MuiStack-root{
  margin: 0 auto;

}
.search{
  position: relative;

}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root{
  border: 2px solid #fff;
  padding: 1px 9px;
  border-radius: 80px;
  input{
    color: #ccc;
    outline: none !important;
  }

  button{
    svg{
      fill: #fff;
    }
  }
}

.burger{
  margin-top: 1rem;
  cursor: pointer;
  svg{
    fill: #fff;
    width: 30px;
    height: 30px;
    position: relative;
    top: -0.5rem;
  }
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    display: none;
}

`
