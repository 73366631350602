import styled from "styled-components";


export const ContentAdmin = styled.div`
    position: relative;
`

export const HeaderAdmin = styled.header`
width: 100vw;
height: 300px;
background-color: #011094;
h1{
    font-size: 4em;
    font-weight: bold;
    color:white ;
}
`

export const ListAdmin = styled.ul`
    display: flex;
    gap: 1rem;
    justify-content: center;
    background-color: #ccc;
    width: 100%;
    padding: 1rem 0;
    li{
        list-style: none;
        text-align: center;
        color: #000;
        font-weight: bold;
        font-size: 1.2em;
        cursor: pointer;
        transition: .3s;
        height: 100%;
        &:hover{
            background-color: #000000;
            color: #ccc;
        }
    }

`