import { Col, Container, Row } from "react-bootstrap";
import { Content } from "./styled";

const Sobre = () => {
  return (
    <>
      <Content>
        <Container className="pb-5">
          <Row className="justify-content-center ">
            <Col className="pb-3" xs={12}>
              <h2>Sobre nós</h2>
            </Col>

            <Col md={10} xs={12} className="sobre-1">
              <p className="text-center">
                Nossa Empresa foi fundada em 1997 e vem investindo maciçamente
                em recursos tecnológicos e em sustentabilidade. Desenvolvendo
                produtos para melhor satisfazer as necessidades dos nossos
                clientes. A força de trabalho de seus dirigentes e funcionários
                permite que a Rocket Tail realize com eficiência e qualidade os
                projetos criados até hoje: Painéis, Chicotes, Tacômetros,
                Horímetro, Voltimetro, Amperímetro, Medidor de Combustível,
                Drive de Motor de Passo, CLP, Medidor de bateria e Hallmeter
                etc.
              </p>
            </Col>
          </Row>
        </Container>
      </Content>
    </>
  );
};

export default Sobre;
