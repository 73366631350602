import Footer from "../components/Footer/Footer";
import HomeCarousel from "../components/HomeCarousel/HomeCarousel";
import ImageHome from "../components/ImageHome/ImageHome";
import Sobre from "../components/Sobre/Sobre";

const Home = () => {
  return (
    <>
      <ImageHome data-aos="fade-up"/>
      <HomeCarousel data-aos="fade-up" data-aos-offset="10"></HomeCarousel>
      <Sobre data-aos="fade-right"></Sobre>
      <Footer></Footer>
    </>
  );
};

export default Home;
