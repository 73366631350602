import { useEffect, useState } from "react";
import AdminProdutos from "./Produtos/Admin_Produtos";
import { Col, Container, Row } from "react-bootstrap";
import { HeaderAdmin, ListAdmin } from "./styled";
import AdminCatalogo from "./Catalogo/Admin_Catalogo";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const url = process.env.REACT_APP_URL_API;

const Admin = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token')
  const [indexRender, setIndexRender] = useState<number>(0);
  const options = [
    {
      name:"Produtos",
      retorno:(<AdminProdutos></AdminProdutos>),
    },
    {
      name:"Catálogos",
      retorno:(<AdminCatalogo></AdminCatalogo>)
    }
  ];
  var mostra: any = options[indexRender].retorno;

  useEffect(()=>{
    console.log(token)
    if(token == null){
      navigate('/login')
    }
    else{
      verificaToken().then(()=> console.log("logado"))
      .catch((err)=>{
        console.log(err)
        navigate('/login')
      })
    }
    console.log(token)
  },[])

  async function verificaToken(){
    const options ={
      headers:{
        token: token
      }
    }
    let response = await axios.post(`${url}/token`, null, options)
    let data = await response.data
    return data
  }

  function changeRender(index: number) {
    setIndexRender(index);
  }

  return (
    <>
    <HeaderAdmin>
    <Container className="h-100">
        <Row className="h-100 justify-content-center align-items-center text-center">
          <Col xs={12}>
            <h1>Admin</h1>
          </Col>
        </Row>
      </Container>
    </HeaderAdmin>

    <Container fluid className="p-0">
      <Row className="justify-content-center ">
        <Col xs={12}>
          <ListAdmin>
          {options.map((item:any, index:number)=>(
            <li  onClick={() => changeRender(index)}>| {item.name} |</li>
        ))} 
          </ListAdmin>
        </Col>
      </Row>
    </Container>

    <Container>
      <Row className="justify-content-center">
        <Col xs={10} className="mx-auto">
            {mostra}
        </Col>
      </Row>
    </Container>
    </>
    
    )
};
export default Admin;
