import { Col, Row } from "react-bootstrap";
import { Content } from "./styled";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import axios from "axios";
import Spin from "../Spin/Spin";
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material";

const url = process.env.REACT_APP_URL_API;


interface FormProps {
  item: any;
  tipo:string
}

const Form = ({ item, tipo }: FormProps) => {
  const [spinOpen, setSpinOpen] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState<any>({
    name: "",
    telephone: "",
    email: "",
    id: item.id,
  });
  useEffect(()=>{
    setValues({...values, id:item.id})
  },[item])

  async function handleSubmit(e: any) {
    e.preventDefault();
    setSpinOpen(true);
  
    try {
      let type;
      // Assuming 'values' and 'tipo' are defined elsewhere
      console.log(values);
  
      if (tipo === "catalogo") {
        type = "catalogo";
      } else if (tipo === "produto") {
        type = "produto";
      } else {
        // Handle case where 'tipo' is neither 'catalogo' nor 'produto'
        throw new Error("Invalid 'tipo' value");
      }
  
      const response = await axios.post(`${url}/send/${type}`, values);
      console.log(response.data); // Optionally, log the response data
  
      setSpinOpen(false);
    } catch (error) {
      console.error("Error during request:", error);
      setSpinOpen(false); // Ensure spinner is closed in case of error
      // You might want to add additional error handling logic here
    }
  }
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
    <Spin open={spinOpen}></Spin>
      <Content>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row className="justify-content-center">
            <Col className="py-3 " xs={12}>
              <h2>Dados para contato</h2>
            </Col>
            <Col xs={6}>
              <input
                required
                type="text"
                placeholder="Name"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
            </Col>

            <Col xs={6}>
              <InputMask
                value={values.telephone}
                onChange={(e) =>
                  setValues({ ...values, telephone: e.target.value })
                }
                required
                mask="(99) 99999-9999"
                placeholder="Telefone"
              ></InputMask>
            </Col>

            <Col xs={12}>
              <input
                required
                type="email"
                placeholder="E-mail"
                value={values.email}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
              />
            </Col>

            <div className="py-5 text-center">
              <button>Solicitar</button>
            </div>
          </Row>
        </form>
        <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
         <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Pedido enviado
      </Alert>
      </Snackbar>
      </Content>
    </>
  );
};

export default Form;
