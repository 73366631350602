import { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Listagem, Produto,Reticencias } from "../../../styled/Admin";
import axios from "axios";
import { ConteudoEditor, Editor } from "../../../styled/Edit";
import Spin from "../../Spin/Spin";
import Edit from "../Edit/Edit";
import CadastraProdutos from "./Castra_Produtos";
import { ButtonCadastrar } from "../../../styled/form";
import EditProdutos from "./Edit_Produtos";
import { Pagination, Stack } from "@mui/material";
import { useSelector } from "react-redux";
const url = process.env.REACT_APP_URL_API;


const AdminProdutos = () => {
const [page, setPage] = useState<number>(1)
const [produtos, setProdutos] = useState<any>("")
const [spinOpen, setSpinOpen]= useState<boolean>(false)
const [editorOpen, setEditorOpen] = useState<boolean>(false)
const [typeProduto, setTypeProduto] = useState<string>("")
const [retorno, setRetorno] = useState<any>("")
const [updated, setUpdated] = useState<number>(0)
const {count} = useSelector((root:any) => root.produtosReducer)
const [itensPerPage, setitensPerPage] = useState<any>(6)
const pages = Math.ceil(count/itensPerPage)

useEffect(()=>{
  setSpinOpen(true)
  getProducts().then((res)=>{
    setProdutos(res.rows)
    setSpinOpen(false)
  })
},[page, updated])
  async function getProducts(){
  const response = await axios.get(`${url}/produto/0/${page}`)
  const data = await response.data
  return data
}

function fechaEditor(){
  setEditorOpen(false)
}
const render = (type:string) =>{
  setEditorOpen(true)
  if(type == "cadastrar"){setRetorno(<CadastraProdutos update={() => Updated()}/>)}

}

async function openListar(produto:any){
  setRetorno(<EditProdutos produto={produto} update={() => Updated()}></EditProdutos>)
  render("")
}

function Updated(){
  setUpdated(updated + 1)
  }


  async function changeProducts(page:number){
    setSpinOpen(true)
    setPage(page)
    try {
        await axios.get(`${url}/produto/1/${page}`)
        .then((res)=>{
            setProdutos(res.data.rows)
            setSpinOpen(false)
            window.scrollTo(0,0)
        })

    } catch (error) {
        setSpinOpen(false)
    }

}

return (
      <>
      <Spin open={spinOpen}></Spin>
      <Edit open={editorOpen} fecha={() =>fechaEditor()} children={retorno}></Edit>
        {produtos ? 
        <Container>
          <Row className="pt-5 justify-content-center">
            <h2 className="text-center mb-5">PRODUTOS</h2>
            <Col xs={10} className="text-start">
            <ButtonCadastrar onClick={() =>render("cadastrar")}>Cadastrar</ButtonCadastrar>
            </Col>
          </Row>
        <Row className="justify-content-center pt-5">
          <Col md={10} xs={12} className="">
            <Listagem>
             {produtos.map((item:any, index:number)=>(
               <Produto>
               <p>{item.name}</p>
               <Reticencias onClick={() => openListar(item)}>...</Reticencias>
             </Produto>
             ))}
            </Listagem>
          </Col>
        </Row>

        <Row className="justify-content-center">
        <Col xs={12} className=" py-5 ">
              <Stack className="align-items-center" spacing={2}>
                <Pagination
                  page={page}
                  count={pages}
                  color="primary"
                  onChange={(e, currentpage) => changeProducts(currentpage)}
                />
              </Stack>
            </Col>
        </Row>
      </Container>
        : ""}
      </>
    )
  
}


export default AdminProdutos;
