import { useEffect } from "react";
import CardProdutos from "../components/CardProdutos/CardProdutos";
import Footer from "../components/Footer/Footer";
import ImageProdutos from "../components/ImageProduto/ImageProdutos";

const ProdutosScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ImageProdutos ></ImageProdutos>
      <CardProdutos></CardProdutos>
      <Footer></Footer>
    </>
  );
};

export default ProdutosScreen;
