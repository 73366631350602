import styled from "styled-components";


export const Content = styled.div`
h2{
    font-weight: bold;
    position: relative;
    font-size: 3em;
    border-bottom: 1px solid #ccc;
    font-family: 'Montserrat', sans-serif;
}

.card{
    border-radius: 10px;
    margin-bottom: 2rem;
    width: auto;
    height: auto;
    border: none;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 9px 7px 7px #ccc;
    .img-card{
        position: relative;
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-bottom: 100%;

        img{
            position: absolute;
            left: 50%;
            height: 100%;
            min-width: 100%;
            transform: translateX(-50%);
            transition: .5s;

            &:hover{
                scale: 1.02;
            }
        }
    }

   .card-infos{
    flex: 1;
    padding: 1rem .5rem;
    background-color: #fff;
    p{
        font-size: 20px;
        text-align: center;
    }
   }
   .card-btn{
    /* margin: 0 auto; */
    text-align: center;
    padding: 1rem 0rem;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    button{
        padding: .8rem 1.3rem;
        border-radius: 10px 0px 10px 0px;
        border: none;
        background-color: #011094;
        color: #fff;
        font-size: 1.1em;
        transition: .5s;
        &:hover{
            transform: scale(1.05);
        }

    }
   }
}

ul{
    display: flex;
    justify-content: center;
}



`
