import { styled } from "styled-components";
import home from '../../images/home.jpg'
export const Content =styled.div`

position:relative;
height: 500px;
width: 100%;
background-image: url(${home});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
/* opacity: .9; */
&::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0000008c;
  top: 0;
  left: 0;
  z-index: 0;
}
.content-home{
  height: calc(100% - 95px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1{
    font-family: 'Cambria';
    font-weight: bold;
    font-size: 4em;
    color: #fff;
    z-index: 2;
    
    span{
      color: #1975ee;
    }
  }
  p{
    z-index: 2;
    color: #fff;
    text-align: center;
    font-size: 1.5em;
    width: 70%;
    @media screen and (max-width: 767px){
      width: 95%;
    }
  }
}
`
