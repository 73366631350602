import { Link } from "react-router-dom";
import { Content } from "./styled";
import { Col, Container, Row } from "react-bootstrap";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const url = process.env.REACT_APP_URL_API;

const HomeCarousel = () => {
  const { produtos } = useSelector((root: any) => root.produtosReducer);

  const carousel = useRef<any>(null);
  const handleLeftClick = (e: any) => {
    e.preventDefault();
    carousel.current.scrollLeft -= carousel.current.offsetWidth;
  };

  const handleRightClick = (e: any) => {
    e.preventDefault();
    carousel.current.scrollLeft += carousel.current.offsetWidth;
  };
  return (
    <>
      <Content>
        <Container data-aos="fade-up" data-aos-offset="200" >
          <Row className="justify-content-center">
            <Col xs={12}>
              <h2>Mais vendidos</h2>
            </Col>
            <Col className="position-relative" xs={10}>
              <div onClick={handleLeftClick} className="seta-esquerda">
                <IoIosArrowBack />
              </div>
              <div onClick={handleRightClick} className="seta-direita">
                <IoIosArrowForward />
              </div>
              <section className=" carousel-section">
                <div data-animation="left" className="carousel-container">
                  <div className="carousel" ref={carousel}>
                    {produtos.map((products: any) => (
                      <div className="carousel-flex">
                        <div className="carousel-card">
                          <div className="carousel-img">
                            <img
                              src={`${url}/files/produtos/${products.image}`}
                            />
                          </div>
                          <div className="carousel-info">
                            <div className="infos-flex">
                              <p>{products.name}</p>
                            </div>

                            <div className="saiba-mais">
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/produtos`}
                              >
                                <div className="bttn">Saiba Mais</div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="center-button">
                  <div className="buttons">
                    {/* <button onClick={handleLeftClick}><img className="left" src={seta} /></button>
                <button onClick={handleRightClick}><img className="right" src={seta} /></button> */}
                  </div>
                </div>
              </section>
            </Col>
          </Row>
        </Container>
      </Content>
    </>
  );
};

export default HomeCarousel;
