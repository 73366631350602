import { useState } from "react";
import { Button, Form, Input, TextArea,Image } from "../../../styled/form";
import { useNavigate } from "react-router-dom";
import Spin from "../../Spin/Spin";
import axios from "axios";
interface ProdutoProps{
  name:string;
  image:any
}
const url = process.env.REACT_APP_URL_API


const CadastraCatalogo = ({update}:any) => {
  const [preview, setPreview] = useState<string>("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNTK5QTN7bjRgXhzUHiR8o7fNjypmI5P3Ui5Zgpz1hcQ&s")
  const [spinOpen, setSpinOpen] = useState<boolean>(false)
  const [values, setValues] = useState<ProdutoProps>({
    name:"",
    image:""
  })
  const navigate = useNavigate();


  function changeImage(el:any){
    setValues({...values, image:el})
    var imagePreview = URL.createObjectURL(el)
    console.log(imagePreview)
    setPreview(imagePreview)
}

async function handleSubmit(e:any)
{
  e.preventDefault();
  setSpinOpen(true)
  const formData = new FormData()
  const objeto = Object.entries(values)
  for(const [key, value] of objeto){
      formData.append(key, value);
  }
  const options ={
    headers:{
      token: localStorage.getItem('token')
    }
  }
  await axios.post(`${url}/catalogo`, formData, options)
  .then((res)=>{
    update()
    setSpinOpen(false)
  })
  .catch((err)=>{
    console.log(err)
    setSpinOpen(false)
  })
}

    return(
      <Form onSubmit={handleSubmit}>
      <Image src={preview} className=""></Image>
      <Input type="file" onChange={(e:any)=>changeImage(e.target.files[0])} ></Input>
      <Input type="text" value={values.name} onChange={(e)=> setValues({...values, name:e.target.value})} placeholder="Nome"></Input>
      {/* <Input type="text" value={values.categoria} onChange={(e)=> setValues({...values, categoria:e.target.value})} placeholder="Categoria"></Input> */}
      <Button type="submit">Cadastrar</Button>
    </Form>
    )
  
  
};

export default CadastraCatalogo;
