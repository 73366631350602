import styled from "styled-components";


export const Content = styled.div`

position: relative;
margin-top: 3rem;

h2{
    font-weight: bold;
    position: relative;
    font-size: 3em;
    border-bottom: 1px solid #ccc;
}
.carousel-section{
    width: 100%;
    position: relative;


}
.carousel-container{
    width: 80%;
    margin: 0 auto;
}
.carousel{
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
}
.carousel-flex{
    display: flex;
}
.carousel::-webkit-scrollbar{
    display: none;
}
.carousel-card{
    margin: 10px;
    padding: 10px;
    width: 280px;
    text-align: center;
    flex: none;
}
.carousel-img{
    border: 2px solid #fff;
    /* border-radius: 10px; */
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
}
.carousel-img img{
    border-radius: 10px 10px 0px 0px;
    min-width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.carousel-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    a{
        color: #fff;
        cursor: pointer;
        font-weight: bold;
        font-size: 1.1em;
    }
}
.buttons{
    display: flex;
    justify-content: center;

}
.center-button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttons img{
    height: 50px;
    margin-bottom: 30px;
}
.buttons > button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.left{
    transform: rotate(180deg);
}
.infos{
    display: flex;
    flex-direction: column;

}
.infos-flex{
    display: flex;
    flex-direction: column;
    p{
        flex:1;
        min-height: 30px;
        max-height: 30px;
        text-overflow: ellipsis;
        margin-top: .5rem;
        font-size: 1.1em;
        text-align: start;
    }
}
.saiba-mais{
    /* display: flex; */
    flex: 1;
    background-color: #011094;
    border-radius: 10px 0 10px 0;
    margin-top: 1.5rem!important;
    transition: 1s;
    width: 120px;
    font-size: 15px;
    padding: 0.5rem .8rem;
    text-align: center;
    /* margin: 0 auto; */
    transition: .5s;
    cursor: pointer;
    &:hover{
        scale: 1.05;
    }
}

.seta-esquerda{
    position: absolute;
    top: 0;
    /* left: 3rem; */
    width: 30px;
    height: 100%;

    display: flex;
    align-items: center;
    transition: .5s;
    z-index: 3;
    cursor: pointer;
    svg{
        width: 40px;
         height: 40px;
    }
    &:hover{
        background-color: #f2f2f280;
    }
}
.seta-direita{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;

    display: flex;
    align-items: center;
    transition: .5s;
    z-index: 3;
    &:hover{
        background-color: #f2f2f280;
    }
    svg{
        width: 40px;
         height: 40px;
    }
}
`
