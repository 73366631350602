import Admin from "../components/Admin/Admin"

const AdminPage = () =>
{
    return (
        <>
            <Admin></Admin>
        </>
    )
}

export default AdminPage