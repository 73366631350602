import NavBar from "../Navbar/NavBar";
import { Content } from "./styled";

const ImageCatalogo = () => {
  return (
    <>
      <Content>
        <NavBar></NavBar>
      </Content>
    </>
  );
};
export default ImageCatalogo;
