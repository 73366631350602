import NavBar from "../Navbar/NavBar";
import { Content } from "./styled";

const ImageHome = () => {
  return (
    <>
      <Content>
        <NavBar />
        <div className="content-home">
          <h1 className="pb-3" data-aos="fade-right" data-aos-offset="200">Rocket <span>Tail</span></h1>
          <p data-aos="fade-left" data-aos-offset="200">Desde 1997 inovando e trabalhando com as melhores tecnologias do mercado, afim de entregar o melhor produto e melhor performance.</p>
        </div>
      </Content>
    </>
  );
};

export default ImageHome;
