import styled from "styled-components";
import produto from '../../images/produto.jpg'
export const Content = styled.div`

position:relative;
height: 300px;
width: 100%;
background-image: url(${produto});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
/* opacity: .9; */
&::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0000008c;
  top: 0;
  left: 0;
  z-index: 0;
}
`