import { Content } from "./styled"

interface SpinProps{
    open:boolean
}

const Spin = ({open}:SpinProps) =>{

    return (
        <>
            <Content open={open}>
          
                <div className="spin"></div>
            
            </Content>
        </>
    )
}

export default Spin