import { Col, Container, Row } from "react-bootstrap"
import { Button, Form, Input } from "../../../styled/form"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spin from "../../Spin/Spin";
const url = process.env.REACT_APP_URL_API;

const Login = () =>{
    const navigate = useNavigate();
    const [name, setName] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [spinOpen, setSpinOpen] = useState<boolean>(false)
    async function handleSubmit(e:any)
    {
        setSpinOpen(true)
        e.preventDefault()
        await axios.post(`${url}/login`,{name:name, password:password}).then((res)=>{
            let token = res.data.token;
            localStorage.setItem('token', token)
            setSpinOpen(false)
            navigate('/_admin')
        })
        .catch((err)=>{
            setSpinOpen(false)
        })
    }

    return (
        <>
        <Spin open={spinOpen}></Spin>
            <Container className="" style={{height:'100vh'}}>
                <Row className="align-items-center h-100">
                   <Col xs={10}>
                    <h2 className="text-center">Login</h2>
                        <Form onSubmit={handleSubmit}>
                            <Input value={name} onChange={(e)=> setName(e.target.value)} type='text' placeholder="Usuário"></Input>
                            <Input autoComplete="password" value={password} onChange={(e)=> setPassword(e.target.value)} type='password' placeholder="Senha"></Input>
                            <Button type="submit">Logar</Button>
                        </Form>
                    </Col> 
                </Row>
            </Container>
        </>
    )
}

export default Login