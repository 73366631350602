import { Content } from "./styled";

const Skeleton = () => {
  return (
    <>
      <Content>
        <div className="header"></div>

        <div className="body"></div>
      </Content>
    </>
  );
};

export default Skeleton;
