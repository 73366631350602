import styled from "styled-components"
interface EditorProps{
    open:boolean
}
export const Editor = styled.div<EditorProps>`
    display: ${(props) => props.open ? "block" : "none"};
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;
    height: 100vh;
    background-color: #fff;
    z-index: 998;
    border: 5px solid #011094;
    overflow-y: scroll;
    overflow-x: hidden;
    @media screen and (max-width: 767px)
    {
        width: 90%;
    }

    svg{
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: .3s;
        &:hover{
            transform: scale(1.1);
        }
    }

`

export const ConteudoEditor = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
padding: 1rem ;
`