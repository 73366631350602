import { Col, Container, Row } from "react-bootstrap";
import { Content } from "./styled";
import { useEffect, useState } from "react";
import axios from "axios";
import ModalCatalogo from "../ModalCatalogo/ModalCatalogo";
import Spin from "../Spin/Spin";
const url = process.env.REACT_APP_URL_API;

async function getCatalogo() {
  const req = await axios.get(`${url}/catalogo`);
  const data = await req.data;
  return data;
}

const CardCatalogo = () => {
  const [catalogoFilter, setCatalogoFilter] = useState<any>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [spin, setSpin] = useState<boolean>(false);
  const [catalogos, setCatalogos] = useState<any>("");
  const [load, setLoad] = useState<boolean>(true);

  useEffect(() => {
    getCatalogo().then((res) => {
      setLoad(false);
      setCatalogos(res);
    });
  }, []);

  function changeModal(item: any) {
    setCatalogoFilter(item);
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }

  return (
    <>
      <ModalCatalogo
        fecha={closeModal}
        open={modalOpen}
        catalogo={catalogoFilter}
      ></ModalCatalogo>
      <Spin open={spin}></Spin>

      {load ? (
        ""
      ) : (
        <Content>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} className="pt-5">
                <h2>Catálogos</h2>
              </Col>
              <Col xs={10}>
                <Row id="produtos" className="justify-content-center">
                  {catalogos.map((item: any, index: number) => (
                    <Col md={6} lg={4} sm={10} className="d-flex">
                      <div className="card">
                        <div className="-img-card">
                          <img
                          className="img-fluid"
                            src={`${url}/files/catalogo/${item.image}`}
                            alt=""
                          />
                        </div>
                        <div className="card-infos">
                          <p>{item.name}</p>
                        </div>
                        <div className="card-btn">
                          <button onClick={() => changeModal(item)}>
                            Orçamento
                          </button>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </Content>
      )}
    </>
  );
};

export default CardCatalogo;
