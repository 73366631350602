import { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Listagem, Produto,Reticencias } from "../../../styled/Admin";
import axios from "axios";
import { ConteudoEditor, Editor } from "../../../styled/Edit";
import Spin from "../../Spin/Spin";
import Edit from "../Edit/Edit";
import CadastraCatalogo from "./Cadastra_Catalogo";
import { ButtonCadastrar } from "../../../styled/form";
import EditCatalogo from "./EditCatalogo";
const url = process.env.REACT_APP_URL_API;


const AdminCatalogo = () => {
const [page, setPage] = useState<number>(1)
const [catalogo, setCatalogo] = useState<any>("")
const [spinOpen, setSpinOpen]= useState<boolean>(false)
const [editorOpen, setEditorOpen] = useState<boolean>(false)
const [typeProduto, setTypeProduto] = useState<string>("")
const [retorno, setRetorno] = useState<any>("")
const [updated, setUpdated] = useState<number>(0)
useEffect(()=>{
  setSpinOpen(true)
  getProducts().then((res)=>{
    console.log(res)
    setCatalogo(res)
    setSpinOpen(false)
  })
},[page, updated])
  async function getProducts(){
  const response = await axios.get(`${url}/catalogo`)
  const data = await response.data
  return data
}

function fechaEditor(){
  setEditorOpen(false)
}
const render = (type:string) =>{
  setEditorOpen(true)
  if(type == "cadastrar"){setRetorno(<CadastraCatalogo update={() => Updated()}/>)}

}
function Updated(){
setUpdated(updated + 1)
}

async function openListar(catalogo:any){
  setRetorno(<EditCatalogo catalogo={catalogo} update={() => Updated()}></EditCatalogo>)
  render("")
}

return (
      <>
      <Spin open={spinOpen}></Spin>
      <Edit open={editorOpen} fecha={() =>fechaEditor()} children={retorno}></Edit>
        {catalogo ? 
        <Container>
          <Row className="pt-5 justify-content-center">
          <h2 className="text-center mb-5">Catálogos</h2>
            <Col xs={10} className="text-start">
            <ButtonCadastrar onClick={() =>render("cadastrar")}>Cadastrar</ButtonCadastrar>
            </Col>
          </Row>
        <Row className="justify-content-center pt-5">
          <Col md={10} xs={12} className="">
            <Listagem>
             {catalogo.map((item:any, index:number)=>(
               <Produto>
               <p>{item.name}</p>
               <Reticencias onClick={() => openListar(item)}>...</Reticencias>
             </Produto>
             ))}
            </Listagem>
          </Col>
        </Row>
      </Container>
        : ""}
      </>
    )
  
}


export default AdminCatalogo;
