import { Col, Container, Row } from "react-bootstrap"
import { Content } from "./styled"
import {BsTelephone, BsWhatsapp} from 'react-icons/bs'
import {AiOutlineMail} from 'react-icons/ai'

const Footer = () =>{


    return (
        <>
            <Content>
                <Container fluid className="p-0">
                    <Row className="p-0 -justify-content-center">
                        <Col className="p-0" md={6} xs={12}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.768914781661!2d-46.63404612498749!3d-23.684220578715596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce44e1dd785691%3A0xf746387552176822!2sR.%20S%C3%A3o%20Lucas%2C%2049%20-%20Centro%2C%20Diadema%20-%20SP%2C%2009913-060!5e0!3m2!1spt-BR!2sbr!4v1689614965817!5m2!1spt-BR!2sbr"  ></iframe>
                        </Col>
                        <Col className="p-0" md={6} sm={12}>
                            <div className="infos-footer">
                                <h2>Contatos</h2>
                                <ul>
                                    <li><span><BsTelephone/></span>+55 (11) 4054-4036</li>
                                    <li><span><BsWhatsapp/></span>+55 (11) 94860-4493</li>
                                    <li><span><AiOutlineMail/></span>suporte@rockettail.com.br</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Content>
        </>
    )
}

export default Footer