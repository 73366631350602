import styled from "styled-components";
import rocket from '../../images/rocket.png'

export const Content = styled.div`
padding-top: 3rem;

h2{
    font-weight: bold;
    position: relative;
    font-size: 3em;
    border-bottom: 1px solid #ccc;
}



.sobre-1{
    background-image: url(${rocket});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    height: auto;
    min-height: 350px;
    padding: 1.5rem;

    p{
        color: #fff;
        z-index: 3;
        line-height: 1.4;
        /* font-weight: bold; */
        font-size: 1.2em;
    }
    &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #00000080;
        z-index: 2;
    }
}
`
