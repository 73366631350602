import { useEffect, useState } from "react"
import { Button, Form, Input, TextArea,Image } from "../../../styled/form"
import Spin from "../../Spin/Spin";
import axios from "axios";

interface EditCatalogoProps{
    catalogo:any
    update:any
}
interface ProdutoProps{
    name:string;
    image:any
    id:string
  }
  const url = process.env.REACT_APP_URL_API

const EditCatalogo = ({catalogo, update}:EditCatalogoProps) =>{

    useEffect(()=>{
        setValues({ 
        id:catalogo.id,
        name:catalogo.name,
        image:catalogo.image
        })
        console.log(catalogo.image)
      },[catalogo])


    const [preview, setPreview] = useState<string>(catalogo.image)
    const [spinOpen, setSpinOpen] = useState<boolean>(false)
    const [values, setValues] = useState<ProdutoProps>({
        id:catalogo.id,
        name:catalogo.name,
        image:catalogo.image
      })



    function changeImage(el:any){
        setValues({...values, image:el})
        var imagePreview = URL.createObjectURL(el)
        console.log(imagePreview)
        setPreview(imagePreview)
    }
    
    async function handleSubmit(e:any)
    {
      e.preventDefault();
      setSpinOpen(true)
      const options ={
        headers:{
          token: localStorage.getItem('token')
        }
      }
      await axios.put(`${url}/produto`,{name:values.name, id:values.id} ,options )
      
      .then((res)=>{
        setSpinOpen(false)
        update()
        console.log(values)
      })
      .catch((err)=>{
        console.log(err)
        setSpinOpen(false)
      })
    }

    return(
        <>
        <Spin open={spinOpen}></Spin>
             <Form onSubmit={handleSubmit}>
          <Image src={`${url}/files/catalogo/${preview}`} className="mb-5"></Image>
          {/* <label htmlFor="name">Nome:</label> */}
          <Input id="name" type="text" value={values.name} onChange={(e)=> setValues({...values, name:e.target.value})} placeholder="Nome"></Input>
          {/* <Input type="text" value={values.categoria} onChange={(e)=> setValues({...values, categoria:e.target.value})} placeholder="Categoria"></Input> */}
          <Button type="submit">Editar</Button>
        </Form>
        </>
    )
}

export default EditCatalogo