import styled from "styled-components";

interface SpinProps{
    open:boolean
}

export const Content = styled.div<SpinProps>`

position: fixed;
left: 0;
top: 0;
width: 100vw;
height: 100vh;
min-height: 100%;
display: ${props => props.open ? 'flex' : 'none' } ;
background-color: #00000080;
z-index: 10000;
justify-content: center;
align-items: center;
.spin{
    width: 50px;
      height: 50px;
      border-radius: 50%;
      border-top: 3px solid #011094;
      border-left: none;
      border-right: none;
      border-bottom: none;
      animation: spin 1s linear infinite;
    }

    @keyframes spin{
  0%{
    transform:rotate(0)
  }
  100%{
    transform: rotate(360deg);
  }
}
`