import Form from "../Form/Form";
import { Content } from "./styled";
import { AiOutlineClose } from "react-icons/ai";
import icon from "../../images/icon.png";
interface ModalProdutosProps {
  open: boolean;
  produto: any;
  fecha: any;
}
const url = process.env.REACT_APP_URL_API;

const ModalProdutos = ({ open, produto, fecha }: ModalProdutosProps) => {
  return (
    <>
      <Content open={open}>
        <div className="ModalClicavel" onClick={() => fecha()}></div>
        <div className="modal">
          <div className="header-modal">
            <span className="text-start w-50">
              <img src={icon} className="img-fluid" alt="" />
            </span>
            <span onClick={() => fecha()} className="text-end w-100 close">
              <AiOutlineClose />
            </span>
          </div>
          <div className="conteudo-modal pb-5">
            <div className="img-responsive">
              <div className="img-modal text-start">
                <img
                  className="img-fluid"
                  src={`${url}/files/produtos/${produto.image}`}
                  alt=""
                />
              </div>
            </div>
            <p>{produto.name}</p>
            {/* <h1 className="mb-3">Descrição</h1> */}
            <p>{produto.description}</p>
          </div>
          <Form item={produto} tipo="produto"></Form>
        </div>
      </Content>
    </>
  );
};

export default ModalProdutos;
