import styled from "styled-components";

interface ModalProps{
    open:boolean
}

export const Content = styled.div<ModalProps>`
position: fixed;
top: 0;
left: 0;
z-index: 998;
width: 100vw;
height: 100vh;
display: ${props => props.open ? 'flex' : 'none'};
background-color: #00000080;
justify-content: center;
align-items: center;
::-webkit-scrollbar {
  width: 8px;

}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ModalClicavel{
  position: fixed;
top: 0;
left: 0;
z-index: 998;
width: 100vw;
height: 100vh;
background-color: #00000080;
}

.modal{
  border-radius: 5px;
    background-color: #fff;
    width: 40em;
    height: 550px;
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    border: 10px solid #011094;
    animation: ${props => props.open ? "animaModal .3s linear forwards" : ""};
    @media screen and (max-width: 767px){
      width: 30rem;
    }
    .header-modal{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
      .close{
        cursor: pointer;
        margin-bottom: 3rem;
        padding-top:1rem;
        svg{
            transition: .5s;
            &:hover{
                transform: scale(1.2);
            }
        }
    }
    }

    .img-modal{
      text-align: center;
      margin-bottom: 1.5rem;
   }

}

p{
  font-size: 1.2em;
}
@keyframes animaModal{
  0%{
    transform: scale(.3);
  }
  100%{
    transform: scale(1);
  }
}
`
