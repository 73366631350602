import produtosActionTypes from "./produtosActionTypes"

const initialState = {
  produtos:null,
  count:null
}

const produtosReducer = (state = initialState, action:any)=>{
  if(action.type === produtosActionTypes.add){
    return {...state, produtos:action.payload.products, count:action.payload.count}
  }
  return state
}

export default produtosReducer
