import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Content } from "./styled";
import { useState } from "react";
import { Pagination, Stack } from "@mui/material";
import axios from "axios";
import Spin from "../Spin/Spin";
import ModalProdutos from "../ModalProdutos/ModalProdutos";
const url = process.env.REACT_APP_URL_API;

const CardProdutos = () =>{
    const {produtos} = useSelector((root:any) => root.produtosReducer)
    const {count} = useSelector((root:any) => root.produtosReducer)
    const [itensPerPage, setitensPerPage] = useState<any>(6)
    const [productsFilter, setProductsFilter] = useState<any>(produtos)
    const [page, setPage] = useState<number>(1)
    const [spin, setSpin] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [selectedProduct, setSelectedProduct] = useState<any>("")
    const pages = Math.ceil(count/itensPerPage)
    function clickbutton(e:any){
        e.preventDefault()
        const prod = document.getElementById('product')
        prod?.scrollIntoView()
    }
    async function changeProducts(page:number){
        setSpin(true)
        setPage(page)
        try {
            await axios.get(`${url}/produto/1/${page}`)
            .then((res)=>{
                setProductsFilter(res.data.rows)
                setSpin(false)
                window.scrollTo(0,0)
            })

        } catch (error) {
            setSpin(false)
        }

    }

    function changeModal(item:any){
        setSelectedProduct(item)
        setModalOpen(true)
    }
    function closeModal(){
        setModalOpen(false)
    }

  return (
    <>
      <ModalProdutos
        fecha={closeModal}
        open={modalOpen}
        produto={selectedProduct}
      ></ModalProdutos>
      <Spin open={spin}></Spin>
      <Content>
        <Container>
            <Row className="justify-content-center">
                <Col xs={10} className="pt-5">
                    <h2>Produtos</h2>
                </Col>
                <Col xs={10}>
                  <Row id='produtos' className="justify-content-center">
                  {productsFilter.map((item:any, index:number)=>(
                    <Col md={6} lg={4} sm={10} className="d-flex">
                         <div className="card">
                            <div className="img-card">
                                <img src={`${url}/files/produtos/${item.image}`} alt="" />
                            </div>
                            <div className="card-infos">
                                <p>{item.name}</p>
                            </div>
                            <div className="card-btn">
                                <button onClick={() => changeModal(item)}>Orçamento</button>
                            </div>
                        </div>
                    </Col>
                   ))}
                  </Row>
                </Col>

            <Col xs={12} className=" py-5 ">
              <Stack spacing={2}>
                <Pagination
                  page={page}
                  count={pages}
                  onClick={clickbutton}
                  color="primary"
                  onChange={(e, currentpage) => changeProducts(currentpage)}
                />
              </Stack>
            </Col>
          </Row>
        </Container>
      </Content>
    </>
  );
};

export default CardProdutos;
