import CardCatalogo from "../components/CardCatalogo/CardCatalogo";
import ImageCatalogo from "../components/ImageCatalogo/ImageCatalogo";

const Catalogo = () => {
  return (
    <>
      <ImageCatalogo />
      <CardCatalogo />
    </>
  );
};

export default Catalogo;
