import Form from "../Form/Form";
import { Content } from "./styled";
import { AiOutlineClose } from "react-icons/ai";
import icon from "../../images/icon.png";
const url = process.env.REACT_APP_URL_API;

interface ModalCatalogoProps {
  open: boolean;
  catalogo: any;
  fecha: any;
}
const ModalCatalogo = ({ open, catalogo, fecha }: ModalCatalogoProps) => {
  return (
    <>
      <Content open={open}>
        <div className="ModalClicavel" onClick={() => fecha()}></div>
        <div className="modal">
          <div className="header-modal">
            <span className="text-start w-50">
              <img src={icon} className="img-fluid" alt="" />
            </span>
            <span onClick={() => fecha()} className="text-end w-100 close">
              <AiOutlineClose />
            </span>
          </div>
          <div className="conteudo-modal pb-5">
            <div className="img-responsive">
              <div className="img-modal">
                <img
                  className="img-fluid mb-3"
                  src={`${url}/files/catalogo/${catalogo.image}`}
                  alt=""
                />
              </div>
            </div>
            <p>{catalogo.name}</p>
          </div>
          <Form item={catalogo} tipo="catalogo"></Form>
        </div>
      </Content>
    </>
  );
};

export default ModalCatalogo;
