import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./scss/App.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import produtosActionTypes from "./redux/produtos/produtosActionTypes";
import Rotas from "./routes";
import Skeleton from "./components/Skeleton/Skeleton";
import AOS from 'aos'
import 'aos/dist/aos.css'; 
const url = process.env.REACT_APP_URL_API;

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; 

async function getProducts() {
  var header = {
    headers:{
      'Access-Control-Allow-Origin':'*'
    }
  }
  const req = await axios.get(`${url}/produto/0/1`, header);
  const data = req.data;
  return data;
}

function App() {
  const [load, setLoad] = useState<boolean>(false);
  const produtos = useSelector((root: any) => root.produtosReducer.produtos);
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init(); 
    getProducts().then((res) => {
      const products = res.rows;
      const count = res.count;
      dispatch({
        type: produtosActionTypes.add,
        payload: { products, count },
      });
      setLoad(true);
    })
    .catch((err)=>console.log(err))
  }, []);

  return <>{load ? <Rotas></Rotas> : <Skeleton />}</>;
}

export default App;
