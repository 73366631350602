import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import ProdutosScreen from "./pages/Produtos";
import Catalogo from "./pages/Catalogo";
import AdminPage from "./pages/Admin";
import Login from "./components/Admin/Login/Login";

const Rotas = () => {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="*" element={<Home></Home>}></Route>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/produtos" element={<ProdutosScreen />}></Route>
        <Route path="/catalogo" element={<Catalogo />}></Route>
        <Route path="/_admin" element={<AdminPage />}></Route>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Rotas;
