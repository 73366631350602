import styled from 'styled-components'


export const Listagem = styled.div`
display: flex;
flex-direction: column;
justify-content: center;


`

export const Produto = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
background-color: #fff;
padding: .5rem 1rem .1rem 1rem;
transition: .3s;
&:hover{
    background-color: #3e3e3e !important;
    color: #fff;
}
&:nth-child(2n)
{
    background-color: #cccccc60;
}
p{
    max-width: 80%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin: 0;
    text-transform: uppercase;
    }
`

export const Reticencias = styled.p`
font-weight: bold;
font-size: 2em;
cursor: pointer;
position: relative;
height: 100%;
top: -0.5rem;
max-width: 100%;
`